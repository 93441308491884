import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../app/modules/User/_redux/userRedux";
import { actions as TRAVERSE_HISTORY_ACTIONS } from "../../../../app/modules/TraversHistory/_redux/TraversHistoryRedux";
import { toAbsoluteUrl } from "../../../_helpers";
import RingLoader from "react-spinners/RingLoader";
import "./common.css";
import {
  getUserIsLoading,
  getUserTraverseQuestion,
} from "../../../../app/modules/User/_redux/userSelectors";
import { IconButton } from "@material-ui/core";
import { format } from "date-fns";
import parseISO from "date-fns/parseISO";
import { TraversHistoryDialoge } from "../../modals/traversHistory-Dialog/TraversHistoryDialoge";
import { HistoryReportDialogue } from "../../modals/HistoryReportDialogue/HistoryReportDialogue";
import HelpIcon from "../../Help/HelpIcon";
export function HistoryDetailsTableWidget({ className }) {
  const dispatch = useDispatch();
  const questions = useSelector(getUserTraverseQuestion) || [];
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const isLoading = useSelector(getUserIsLoading);
  const [modalShow, setModalShow] = useState(false);
  const [traversId, setTraversId] = useState("");
  const [reportShow, setReportShow] = useState(false);
  const [transactionId, setTransactionId] = useState("");

  const fetchDetails = (id) => {
    setTraversId(id);
    setModalShow(true);
  };

  useEffect(() => {
    dispatch(actions.fetchUserTraverseQuestions(user.id));
  }, []);

  const print = (reportUrl) => {
    var file_path = reportUrl;
    var a = document.createElement("a");
    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
    a.click();
  };

  return (
    <>
      <div className={`card card-custom`} style={{ marginBottom: 20 }}>
        <div className="card-body py-4 d-flex align-items-center">
          <div>
            <h4 style={{ margine: 0 }}>Traversal History</h4>
          </div>
        </div>
      </div>
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        {isLoading && (
          <div className="loader_wrapper">
            <RingLoader color={"darkcyan"} loading={true} size={40} />
          </div>
        )}
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body py-0">
          {/* begin::Table */}
          <div className="table-responsive">
            <table
              className="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <thead>
                <tr className="text-left">
                  <th className="pl-0" style={{ minWidth: "400px" }}>
                    Tool Name
                  </th>
                  <th style={{ minWidth: "200px" }}>TOOL BOX</th>
                  {/* <th style={{ minWidth: "200px" }}>modified name</th> */}
                  <th
                    style={{ minWidth: "150px", position: "sticky", right: 0 }}
                    className="pr-0 text-left"
                  >
                    Status
                  </th>
                  <th style={{ minWidth: "100px" }}>count</th>
                  <th
                    style={{ minWidth: "150px", position: "sticky", right: 0 }}
                    className="pr-0 text-left"
                  >
                    action
                  </th>
                </tr>
              </thead>
              <tbody>
                {(questions || []).map((question) => (
                  <tr key={`question_${question.questionId}`}>
                    <td className="pl-0 py-4">
                      <div className="d-flex align-items-center">
                        <div>
                          <Link
                            to={`history/questionDetail/${question?.question
                              .split("/")
                              .join("-")}/${question.questionId}`}
                            className="text-dark-75 font-weight-bolder text-hover-primary d-block mb-1 font-size-lg"
                          >
                            {question?.question}
                          </Link>
                          <span className="text-muted font-weight-bold d-block">
                            {/* Modified on {question?.updatedAt} */}
                            Modified on{" "}
                            {format(parseISO(question?.updatedAt), "PPP p")}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                        {question?.topic}
                      </span>
                    </td>
                    {/* <td>
                      <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                        Modified by {question?.updatedBy}
                      </span>
                    </td> */}
                    <td>
                      <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                        {question?.isCompleted ? (
                          <div
                            style={{
                              color: "green",
                              fontWeight: 500,
                              fontSize: 12,
                            }}
                          >
                            Completed
                          </div>
                        ) : (
                          <div
                            style={{
                              color: "#eb800a",
                              fontWeight: 500,
                              fontSize: 12,
                            }}
                          >
                            In Progress
                          </div>
                        )}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                        {question?.count}
                      </span>
                    </td>
                    <td
                      style={{ position: "sticky", right: 0 }}
                      className="pr-0 text-left"
                    >
                      <IconButton
                        className="btn btn-icon btn-light btn-hover-primary iconBtn"
                        onClick={fetchDetails.bind(
                          this,
                          question?.transactionId
                        )}
                        aria-label="detail"
                      >
                        <span className="svg-icon d-block p-1 view-icon">
                          <SVG
                            src={toAbsoluteUrl("/media/svg/ico_view.svg")}
                          ></SVG>
                        </span>
                      </IconButton>
                      {question.reportUrl && (
                        <IconButton
                          onClick={(e) => {
                            setTransactionId(question?.transactionId);
                            setReportShow(true);
                          }}
                          className="btn btn-icon btn-light btn-hover-primary iconBtn"
                        >
                          <span className="svg-icon d-block p-1 report-icon">
                            <SVG
                              src={toAbsoluteUrl("/media/svg/ico_report.svg")}
                            ></SVG>
                          </span>
                        </IconButton>
                      )}
                      {question.reportUrl && (
                        <IconButton
                          className="btn btn-icon btn-light btn-hover-primary iconBtn"
                          onClick={print.bind(this, question.reportUrl)}
                          aria-label="detail"
                        >
                          <span className="svg-icon d-block p-1 download-icon">
                            <SVG
                              src={toAbsoluteUrl("/media/svg/ico_download.svg")}
                            ></SVG>
                          </span>
                        </IconButton>
                      )}
                    </td>
                  </tr>
                ))}
                {!(questions || []).length && !isLoading && (
                  <tr>
                    <td className="pl-0 text-center" colSpan={8}>
                      <em>No records </em>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* end::Table */}
        </div>
        {/* end::Body */}
      </div>
      {modalShow && (
        <TraversHistoryDialoge
          show={modalShow}
          toggleModal={(value) => setModalShow(value)}
          traversId={traversId}
        />
      )}
      {reportShow && (
        <HistoryReportDialogue
          show={reportShow}
          toggleModal={(value) => setReportShow(value)}
          transactionId={transactionId}
        />
      )}

      <HelpIcon pageName="UserHistoryListSection" />
    </>
  );
}
