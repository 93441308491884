import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import RingLoader from "react-spinners/RingLoader";
import { TraversalHeader } from "./TraversalHeader/TraversalHeader";
import { StartTravesal } from "./StartTravesal/StartTravesal";
import { SummaryAnalysis } from "./SummaryAnalysis/SummaryAnalysis";
import { MultiState } from "./TraversalComponent/MultiState";
import { CommentComponent } from "./TraversalComponent/CommentComponent";
import { MannualInput } from "./TraversalComponent/MannualInput";
import { ConditionalNode } from "./TraversalComponent/ConditionalNode";
import { EndComponent } from "./TraversalComponent/EndComponent";
import { MultiAns } from "./TraversalComponent/MultiAns";
import { PassData } from "./TraversalComponent/PassData";

// import { actions as WEB_TRAVERS } from "../../../../app/modules/WebTravers/_redux/webTraversRedux";
import "./Traversal.scss";
import HelpIcon from "../Help/HelpIcon";
import ListingDataInput from "./TraversalComponent/ListingDataInput";
import MultiOptionSelectNew from "./TraversalComponent/MultiOptionSelectNew";
export function Traversal() {
  // const [nodeCategory, setNodeCategory] = useState('')

  const { id } = useParams();
  const questionDetails = useSelector(
    (store) => store.questions.questionDetails
  );

  const nodeDetails = useSelector((store) => store.webTravers.nodeData);

  const nodeIsLoading = useSelector((store) => store.webTravers.nodeIsLoading);
  const transactionId = useSelector((store) => store.webTravers.transactionId);
  const subtopicDetails = useSelector((store) => store.questions.subtopicDetails);

  const showNode = (nodeCategory) => {
    switch (nodeCategory) {
      case "Start":
        return (
          <StartTravesal
            nodeData={nodeDetails}
            questionDetails={questionDetails}
            questionId={id}
            transactionId={transactionId}
          />
        );
      case "Decision":
        return (
          <ConditionalNode
            questionId={id}
            nodeData={nodeDetails}
            transactionId={transactionId}
          />
        );
      case "PassData":
        return (
          <PassData
            questionId={id}
            nodeData={nodeDetails}
            transactionId={transactionId}
          />
        );
      case "ManualInput":
        return nodeDetails?.metaData?.type === "File" ? (
          <ListingDataInput
            nodeData={nodeDetails}
            questionId={id}
            transactionId={transactionId}
          />
        ) : nodeDetails?.metaData?.type === "Text" ? (
          <MannualInput
            nodeData={nodeDetails}
            questionId={id}
            transactionId={transactionId}
          />
        ) : null;
      case "MultiOption": {
        if (nodeDetails?.metaData?.localVariableName == "States") {
          return (
            <MultiState
              nodeData={nodeDetails}
              questionId={id}
              transactionId={transactionId}
            />
          );
        } else {
          return (
            <MultiOptionSelectNew
              nodeData={nodeDetails}
              questionId={id}
              transactionId={transactionId}
            />
          );
        }
      }
      case "Report":
        return (
          <SummaryAnalysis
            nodeData={nodeDetails}
            questionId={id}
            transactionId={transactionId}
          />
        );
      case "End":
        return (
          <EndComponent
            nodeData={nodeDetails}
            questionDetails={questionDetails}
            questionId={id}
            transactionId={transactionId}
          />
        );
      case "MultiAns":
        return (
          <>
            <MultiAns
              nodeData={nodeDetails}
              questionDetails={questionDetails}
              questionId={id}
              transactionId={transactionId}
            />
          </>
        );
      case "Comment":
        return (
          <>
            <CommentComponent
              nodeData={nodeDetails}
              questionDetails={questionDetails}
              questionId={id}
              transactionId={transactionId}
            />
          </>
        );
      default:
        return (
          <>
            <StartTravesal
              nodeData={nodeDetails}
              questionDetails={questionDetails}
              questionId={id}
              transactionId={transactionId}
            />
          </>
        );
    }
  };

  return (
    <div className="Traversal__main__block">
      <div className={`card card-custom`}>
        <TraversalHeader
          questionDetails={questionDetails}
          transactionId={transactionId}
        />
        <div className="Traversal__container">
          <div className="Traversal__leftcol">
            <div className="illustration__img">
              <img
                alt="illustration"
                src={
                  subtopicDetails?.tool_image != "" &&
                    subtopicDetails?.tool_image != undefined
                    ? subtopicDetails?.tool_image
                    : "/media/bg/sub-cat-illustration.png"
                }
              />
            </div>
            <div className="Traversal__whitebox">
              <div className="box__title">
                {questionDetails?.text}
                <span className="arrownext"></span>
              </div>
              <div className="boximg">
                <img
                  alt="illustration"
                  src={
                    questionDetails?.icon_logo ||
                    "/media/misc/fleetrater-img.png"
                  }
                />
              </div>
            </div>

            <div className="pow-logo">
              <img alt="powemate" src="/media/logos/pow-emate.png" />
            </div>
          </div>
          <div className="Traversal__Rightcol">
            {!nodeIsLoading && showNode(nodeDetails.type)}
            {nodeIsLoading && (
              <div className="loader_wrapper">
                <RingLoader color={"darkcyan"} loading={true} size={40} />
              </div>
            )}
          </div>
        </div>
      </div>
      <HelpIcon pageName={id} questionId={id} isToolBox={true} />
    </div>
  );
}
