import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RingLoader } from "react-spinners";
import { TraversalFooter } from "../TraversalFooter/TraversalFooter";
import { actions as WEB_TRAVERS } from "../../../../app/modules/WebTravers/_redux/webTraversRedux";
import { lastIndexOf } from "lodash";
import { TraversalRadioButton } from "../TraversalRadioButton/TraversalRadioButton";
import { checkIDExist, groupByColumnName } from "../../../_utils/constants";

const CONDITION_DATA = [
  {
    id: 1,
    conditionLabel: "Ample Supply of Qualified Suppliers.",
    value: "X",
  },
  {
    id: 2,
    conditionLabel: "Caution: There is a Limited Supply of Qualified Suppliers.",
    value: "Y",
  },
  {
    id: 3,
    conditionLabel:
      "Caution: Insufficient Supply Base to Support the Requested Products.",
    value: "",
  },
];

function getSelectionStatus(item, selectedValues, isMultiSelect) {
  // Base case: if the item has no subItems, check if it's selected
  if (!item.subItems || item.subItems.length === 0) {
    const isSelected =
      selectedValues[item.category] &&
      selectedValues[item.category].includes(item.label);
    return isSelected ? "all" : "none";
  }

  // Recursively determine the selection status of all subItems
  let allSelected = true;
  let noneSelected = true;
  for (const subItem of item.subItems) {
    const subItemStatus = getSelectionStatus(
      subItem,
      selectedValues,
      isMultiSelect
    );

    if (subItemStatus !== "all") {
      allSelected = false;
    }
    if (subItemStatus !== "none") {
      noneSelected = false;
    }
  }

  // Determine the selection status of the current item based on its subItems
  if (allSelected) {
    return "all";
  } else if (noneSelected) {
    return "none";
  } else {
    return "partial";
  }
}

function getRoot(item) {
  if (item?.level === 0) return item;

  let parent = item.parent;
  while (parent) {
    if (parent?.level === 0) return parent; // returns root parent
    parent = parent.parent;
  }
  // default break;
  return parent;
}

function formatSingleSelectionData(map, columns) {
  let payload = {};
  columns.forEach((col) => (payload[col] = ""));
  const values = map.values();
  values.forEach((v) => {
    for (let key in v) {
      payload[key] = v[key]?.toString();
    }
  });

  return payload;
}

export default function MultiOptionSelectNew({
  nodeData,
  transactionId,
  questionId,
}) {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [questionAnswer, setQuestionAnswer] = useState("");
  const [showQuestionPage, setShowQuestionPage] = useState(false);
  const [showCommentPage, setShowCommentPage] = useState(false);
  const [dataMap, setDataMap] = useState(new Map());
  const [expandedColumns, setExpandedColumns] = useState({});
  const nodeIsLoading = useSelector((store) => store.webTravers.nodeIsLoading);
  const [expandId, setExpandId] = useState('')
  const userRole = useSelector((store) => store.auth.user);
  const validateVariable = nodeData?.metaData?.isValidateCountry;

  const isSingleSelection = useMemo(() => nodeData?.metaData?.isSingle, [
    nodeData,
  ]);
  const inputColumnsName = useMemo(
    () =>
      (nodeData?.metaData?.inputs || [])
        .filter(item => !Object.values(item).includes("")) // Remove objects with empty strings
        .map((input) => input?.groupByColumn),
    [nodeData]
  );

  const inputColumnsLabel = useMemo(
    () =>
      (nodeData?.metaData?.inputs || [])
        .filter(item => !Object.values(item).includes(""))
        .map(
          (input) => input?.groupByColumnLabel
        ),
    [nodeData.metaData.inputs]
  );

  const getOptions = useCallback(
    (level = 0, data = nodeData?.data, parent = "") => {
      if (level >= inputColumnsName.length) return [];

      const currentColumn = inputColumnsName[level];
      // Get unique values for the current column from filtered data
      const uniqueValues = [
        ...new Set(data.map((item) => item[currentColumn])),
      ];

      return uniqueValues
        .filter((value) => value !== "")
        .map((value) => {
          const filteredData = data.filter(
            (item) => item[currentColumn] === value
          );
          const item = {
            id: parent === '' ? value : `${parent.id}-${value}`,
            level,
            category: currentColumn,
            parent,
            label: value,
            categoryLabel: inputColumnsLabel[level],
          };

          item.subItems = getOptions(level + 1, filteredData, item);
          item.selectionStatus = getSelectionStatus(
            item,
            dataMap?.get(getRoot(item)?.label) || {},
            !nodeData.metaData.isSingle
          );

          return item;
        });
    },
    [
      inputColumnsName,
      nodeData.data,
      dataMap,
      inputColumnsLabel,
      nodeData.metaData.isSingle,
    ]
  );

  const handleExpansion = (column, item, checked) => {
    const columnIndex = inputColumnsName.indexOf(column);
    setExpandId(item.id)
    setExpandedColumns((prev) => {
      const updatedColumns = { ...prev };
      const isCollapsing = !checked && prev?.[column] === item.label;

      // If collapsing, remove all child level expansions
      inputColumnsName.forEach((col, index) => {
        if (index > columnIndex) {
          delete updatedColumns[col];
        } else if (index === columnIndex) {
          updatedColumns[col] = isCollapsing ? null : item.label;
        }
      });

      return updatedColumns;
    });
  };

  const handleSelection = (column, item, checked) => {
    let map
    if (isSingleSelection) {
      map = new Map()
    } else {
      map = new Map(dataMap);
    }
    const nextCol = inputColumnsName[inputColumnsName.indexOf(column) + 1];
    // if checked, expand the item
    if (checked) {
      handleExpansion(column, item, checked);
    }

    // get the top most parent i.e. the root of the current item
    const highestAncestor = getRoot(item);

    // check if the item is already selected
    let dataItem = { ...(map.get(highestAncestor.label) ?? {}) };
    const selected = dataItem ? dataItem[column]?.includes(item.label) : false;

    // if not selected, select the item
    if (!selected) {
      if (isSingleSelection) {
        // if single selection, clear all other selections
        map.clear();
        dataItem[column] = [item.label];
      } else {
        const currentSelection = dataItem[column] || [];
        dataItem[column] = [...currentSelection, item.label];

        // Add child categories of the next level based on the selected parent(s)
        if (nextCol) {
          dataItem[nextCol] = [
            ...(dataItem[nextCol] ?? []),
            ...(item?.subItems?.map((v) => v.label) ?? []),
          ];
        }
      }

      // auto-select the parents for the current selected item
      let parent = item.parent;
      while (parent) {
        dataItem[parent.category] = [
          parent.label,
          ...(isSingleSelection ? [] : dataItem[parent?.category] ?? []),
        ];
        parent = parent.parent;
      }

      map.set(highestAncestor.label, dataItem);
    } else {
      if (isSingleSelection) {
        dataItem[column] = [];
        map.set(highestAncestor.label, dataItem);
      } else {
        const currentSelection = dataItem[column] || [];
        const updatedSelection = currentSelection.filter(
          (value) => value !== item.label
        );
        dataItem[column] = updatedSelection;
        map.set(highestAncestor.label, dataItem);
      }

      // if all the child categories at any level are deselected i.e. is empty, remove the item from the map
      if (Object.values(dataItem).some((value) => value.length === 0)) {
        map.delete(highestAncestor.label);
      }
    }

    setDataMap(map);
  };

  const options = useMemo(getOptions, [nodeData?.data, dataMap]);

  const isValid = useMemo(() => {
    if (showQuestionPage && questionAnswer === '') {
      return false
    }
    return options?.some(
      (opt) =>
        opt.selectionStatus === "all" || opt.selectionStatus === "partial"
    );
  }, [options, showQuestionPage, questionAnswer]);

  const reference = useMemo(() => {
    const singleSelectionPayload = formatSingleSelectionData(
      dataMap,
      inputColumnsName
    );
    const reference = Object.values(singleSelectionPayload)
      ?.join("-")
      ?.replace(/-$/g, "");

    const findObjectReference = nodeData?.data.find((item) => {
      return Object.values(item)?.includes(reference);
    });
    return findObjectReference?.[
      nodeData?.metaData?.validateCountryVariableValue
    ];
  }, [dataMap, inputColumnsName, nodeData]);

  // directly redirect to Question Page if user is not limited access user 
  useEffect(() => {
    const isValidReference = validateVariable && reference === '';

    if (isValidReference) {
      setShowQuestionPage(true);
    }
  }, [reference])

  // handle confirm
  const handleConfirm = () => {
    if (validateVariable) {
      if (questionAnswer === "No") {
        setQuestionAnswer('')
        setShowQuestionPage(false);
        setDataMap(new Map());
        setExpandedColumns({});
        setExpandId('')
        return;
      }
      if (reference === "X" || questionAnswer === "Yes") {
        const payload = !isSingleSelection
          ? [...dataMap.values()]
          : [formatSingleSelectionData(dataMap, inputColumnsName)];

        const data = {
          fromLink: nodeData.fromLink,
          toNode: nodeData._id,
          transactionId: transactionId,
          nextNodeLinkId: nodeData.links[0]._id,
          questionId: questionId,
          metaData: {
            variableName: nodeData.metaData.localVariableName,
            variableId: nodeData.metaData.variableId,
            value: payload,
            isFetchFromLookup: nodeData.metaData.isFetchFromLookup,
            isSingle: nodeData.metaData.isSingle,
            hasGroupBy: nodeData.metaData.hasGroupBy,
          },
        };
        dispatch(WEB_TRAVERS.saveNodeDataApi(data));
      } else if (reference === "") {
        setShowQuestionPage(true);
      } else if (reference === "Y") {
        if (showCommentPage) {
          const payload = !isSingleSelection
            ? [...dataMap.values()]
            : [formatSingleSelectionData(dataMap, inputColumnsName)];

          const data = {
            fromLink: nodeData.fromLink,
            toNode: nodeData._id,
            transactionId: transactionId,
            nextNodeLinkId: nodeData.links[0]._id,
            questionId: questionId,
            metaData: {
              variableName: nodeData.metaData.localVariableName,
              variableId: nodeData.metaData.variableId,
              value: payload,
              isFetchFromLookup: nodeData.metaData.isFetchFromLookup,
              isSingle: nodeData.metaData.isSingle,
              hasGroupBy: nodeData.metaData.hasGroupBy,
            },
          };
          dispatch(WEB_TRAVERS.saveNodeDataApi(data));
        } else {
          setShowCommentPage(true);
        }
      }
    } else {
      const payload = !isSingleSelection
        ? [...dataMap.values()]
        : [formatSingleSelectionData(dataMap, inputColumnsName)];

      const data = {
        fromLink: nodeData.fromLink,
        toNode: nodeData._id,
        transactionId: transactionId,
        nextNodeLinkId: nodeData.links[0]._id,
        questionId: questionId,
        metaData: {
          variableName: nodeData.metaData.localVariableName,
          variableId: nodeData.metaData.variableId,
          value: payload,
          isFetchFromLookup: nodeData.metaData.isFetchFromLookup,
          isSingle: nodeData.metaData.isSingle,
          hasGroupBy: nodeData.metaData.hasGroupBy,
        },
      };
      dispatch(WEB_TRAVERS.saveNodeDataApi(data));
    }
  };

  const conditionColor = () => {
    switch (reference) {
      case "X":
        return "green";
      case "Y":
        return "yellow";
      case "":
        return "red";
      default:
        return "#EBEDF3";
    }
  };

  const referenceRemarkObj = {
    X: 0, Y: 1, "": 2
  }

  // useEffect(() => {
  //   if (inputColumnsName.length === 0) return
  //   const tempData = [...nodeData?.data]
  //   const tempOptions = [...options]
  //   let arrayOfSelectedData = tempData.filter(item => item.isSelected);
  //   arrayOfSelectedData = groupByColumnName(arrayOfSelectedData, inputColumnsName[0])

  //   // 
  //   const selectedCategory = arrayOfSelectedData.map(item => item[inputColumnsName[0]])
  //   const result = tempOptions.filter(obj => selectedCategory.includes(obj.label));

  //   // forEach loop to handle selected item to be checked 
  //   result.forEach(item => {
  //     handleSelection(inputColumnsName[0], item, false)
  //   })
  // }, [])

  // render the options
  const List = ({ options, parent = "" }) => {
    const data = options[0]
    const currentColumn = data?.category;
    const currentLabel = data?.categoryLabel;
    // 
    const nodeLevel = data?.level
    const parentId = data?.parent?.id
    const selectedValue = nodeLevel >= 1 ? checkIDExist(expandId, parentId) : false
    const shouldRender = useMemo(
      () =>
        (nodeLevel > 0 && selectedValue) ||
        nodeLevel === 0,
      [parent, options]
    );

    if (!shouldRender) return null;

    return (
      <>
        <div className="col-md-12 col-lg-3">
          <div className="form-group">
            <h4 className="card-label font-weight-bolder text-dark mb-6">
              {currentLabel}
            </h4>
            <div className="checkbox-block">
              {options.map((option, idx) => {
                const isSelected = dataMap
                  ?.get(getRoot(option)?.label)
                  ?.[currentColumn]?.includes(option.label);

                const isPartialSelection =
                  (option.selectionStatus === "partial" &&
                    !nodeData?.metaData?.isSingle) ||
                  (isSelected && option.selectionStatus !== "all");

                const isExpanded =
                  expandedColumns[currentColumn] === option.label;

                return (
                  <div
                    className="checkbox-inline"
                    key={`${idx}_${option.label}`}
                  >
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        name={option.label}
                        onChange={(e) =>
                          handleSelection(
                            currentColumn,
                            option,
                            e.target.checked
                          )
                        }
                        checked={!!isSelected}
                      />
                      <span
                        className={
                          isPartialSelection
                            ? "mr-0 has-product-selected"
                            : "mr-0"
                        }
                      >
                        {isPartialSelection ? "-" : ""}
                      </span>
                      <div
                        style={
                          (isSelected || isExpanded) &&
                            !!option.subItems?.length
                            ? { color: "#E57F0E" }
                            : {}
                        }
                        className="pl-3 font-weight-bolder"
                      >
                        {option.label}
                      </div>
                    </label>
                    {!!option.subItems?.length && (
                      <div
                        className="arrowright"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleExpansion(currentColumn, option, !!isSelected);
                        }}
                      ></div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {options?.map(
          (opt) =>
            opt?.subItems?.length > 0 && (
              <List
                key={opt?.label}
                options={opt?.subItems}
                parent={opt?.label}
              />
            )
        )}
      </>
    );
  };

  return (
    <>
      {nodeIsLoading ? (
        <div className="loader_wrapper">
          <RingLoader color={"darkcyan"} loading={true} size={40} />
        </div>
      ) : (
        <div className="card card-custom ">
          <div className={`card-body`} ref={ref}>
            <div className="card-header">
              <h3 className="card-title flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  {nodeData?.metaData?.variableLabel}
                </span>
                <span
                  className="d-block mt-3 text-sm pr-12"
                  style={{
                    fontSize: "13px",
                  }}
                >
                  {nodeData?.metaData?.labelDescription}
                </span>
              </h3>
              <TraversalFooter
                clickAble={!isValid}
                nextNode={() => handleConfirm()}
              />
            </div>

            {showQuestionPage ? (
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="traversal__product__block">
                      <div className="input-block">
                        <h4 className="input-title mb-5">
                          <span className="font-weight-bolder text-dark">
                            Given the Limited Supply of Qualified Suppliers, Are You Introducing a New Supplier?
                          </span>
                        </h4>
                        <TraversalRadioButton
                          dataForOption={[
                            { _id: "Yes", label: "Yes" },
                            { _id: "No", label: "No" },
                          ]}
                          onSelect={(value) => setQuestionAnswer(value)}
                        />
                        <h6 className="input-title mt-9">
                          <span className="font-weight-bolder text-dark">
                            If No, please select another country or products to relocate.
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : showCommentPage ? (
              <div className="input-block">
                <h4 className="input-title mb-5">
                  <span className="font-weight-bolder text-warning">
                    Supply Base not Rebust. Move forward with caution
                  </span>
                </h4>
              </div>
            ) : (
              <div className="row">
                <List options={options} />
              </div>
            )}
          </div>
          <div className="card-footer">
            {validateVariable && (
              <div className="condition-main">
                {Object.values(nodeData?.metaData?.validateCountryRemarks).map((item, index) => (
                  <div
                    className="condition-items"
                    key={item}
                    style={{
                      marginBottom: lastIndexOf(index) ? "10px" : "0px",
                    }}
                  >
                    <span
                      className="color-box mr-3"
                      style={{
                        backgroundColor:
                          index === referenceRemarkObj[reference]
                            ? conditionColor()
                            : "white",
                      }}
                    >
                      &nbsp;
                    </span>
                    <h4 className="font-weight-light" style={{ "fontSize": "14px", color: "black" }}>
                      {item}{" "}
                    </h4>
                  </div>
                ))}
              </div>
            )}
            <TraversalFooter clickAble={!isValid} nextNode={handleConfirm} />
          </div>
        </div>
      )}
    </>
  );
}
