import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import _unionBy from "lodash/unionBy";
import { TraversalRadioButton } from "../TraversalRadioButton/TraversalRadioButton";
import { TraversalFooter } from "../TraversalFooter/TraversalFooter";
import { setToast } from "../../../../app/modules/toast";
import { actions as WEB_TRAVERS } from "../../../../app/modules/WebTravers/_redux/webTraversRedux";
import { StateMap } from "../StateMap/StateMap";
import "../Traversal.scss";
import { traversalSectionHeight } from "../../../_utils/constants";

export function MultiState({ nodeData, transactionId, questionId }) {
  const dispatch = useDispatch();

  // const [optionSelection, setOptionSelection] = useState("");
  const [displayColumnName, setDisplayColumnName] = useState("");
  const [state, setState] = useState([]);
  // const [nodeState, setNodeState] = useState([]);
  const [retraverseState, setRetraverseState] = useState([]);
  const [manualSelectedStates, setManualSelectedStates] = React.useState([]);
  const [manualUnselectedStates, setManualUnselectedStates] = React.useState(
    []
  );

  const retraversalData = useSelector(
    (store) => store.webTravers.retraversalData
  );

  useEffect(() => {
    // setNodeState(nodeData.data);
    let nodeStateList = nodeData?.data.map((item) => {
      return item.list != undefined ? item.list : item;
    });
    let nodeStateListGroup = [].concat(...nodeStateList);
    let preSelectState = nodeStateListGroup.reduce((result, _nodeState) => {
      if (_nodeState?.isSelected) {
        result = [...result, _nodeState];
      }
      return result;
    }, []);
    setManualSelectedStates(preSelectState);
    setDisplayColumnName(nodeData?.metaData?.displayColumnName);
  }, [nodeData]);
  useEffect(() => {
    let stateSelectedData = retraversalData.find(
      (item) => Object.keys(item)[0] == "States"
    );

    if (stateSelectedData != undefined) {
      setRetraverseState(stateSelectedData.States);
    }
  }, [retraversalData]);

  const handleStateChange = (states, appliedFilters) => {
    if (appliedFilters) {
      states = _unionBy(
        states,
        manualSelectedStates,
        displayColumnName.split(" ")[1]
      );
    }
    setState(states);
  };

  const handleConfirm = () => {
    if (state.length > 0) {
      let stateData = state.map(function (el) {
        return el._id;
      });

      let data = {
        fromLink: nodeData.fromLink,
        toNode: nodeData._id,
        transactionId: transactionId,
        nextNodeLinkId: nodeData.links[0]._id,
        questionId: questionId,
        metaData: {
          variableId: nodeData.metaData.variableId,
          variableName: nodeData.metaData.localVariableName,
          value: stateData,
          isSingle: nodeData.metaData.isSingle,
          hasGroupBy: nodeData.metaData.hasGroupBy,
        },
      };

      dispatch(WEB_TRAVERS.saveNodeDataApi(data));
    } else {
      setToast("Please select state", "error");
    }
  };
  const [showContinueButtonAbove, setShowContinueButtonAbove] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (ref != null) {
      setShowContinueButtonAbove(
        ref.current.clientHeight > traversalSectionHeight
      );
    }
  });
  return (
    <>
      <div className="card-body" ref={ref}>
        <div className="card-header">
          <h3 className="card-title flex-column">
            <span className="card-label font-weight-bolder text-dark">
              {nodeData?.metaData?.variableLabel}
            </span>
          </h3>
          {showContinueButtonAbove && (
            <TraversalFooter nextNode={() => handleConfirm()} />
          )}
        </div>
        {/* <TraversalRadioButton onSelect={(value) => setOptionSelection(value)} /> */}
        {/* {optionSelection !== '' && <> */}

        <div className="traversal__map__block">
          {displayColumnName != "" && (
            <StateMap
              optionSelection={""}
              nodeState={nodeData.data}
              state={state}
              retraverseState={retraverseState}
              onStateChange={handleStateChange}
              manualSelectedStates={manualSelectedStates}
              setManualSelectedStates={setManualSelectedStates}
              manualUnselectedStates={manualUnselectedStates}
              setManualUnselectedStates={setManualUnselectedStates}
              groupByColumn={displayColumnName.split(" ")[1]}
              columnName={displayColumnName.split(" ")[0]}
              isSingle={nodeData?.metaData?.isSingle}
              nodeType={nodeData?.type}
            />
          )}
        </div>
        {/* </>} */}
      </div>
      <div className="card-footer">
        <TraversalFooter nextNode={handleConfirm} />
      </div>
    </>
  );
}
