import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { TraversalFooter } from "../TraversalFooter/TraversalFooter";
import "../Traversal.scss";
import { actions as WEB_TRAVERS } from "../../../../app/modules/WebTravers/_redux/webTraversRedux";
import { traversalSectionHeight } from "../../../_utils/constants";

export function MannualInput({ nodeData, transactionId, questionId, isConditionalNodeParent = false, onFieldChange }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [valueArray, setValueArray] = useState([]);
  const [errorArray, setErrorArray] = useState([]);
  const retraversalData = useSelector(
    (store) => store.webTravers.retraversalData
  );
  useEffect(() => {
    if (nodeData?.metaData?.inputs.length > 0) {
      let valueNew = [];
      let data = nodeData?.metaData?.inputs?.map((item, index) => {
        let variableName =
          item.variableName !== "" ? item.variableName : item.localVariableName;
        let variableLabel = item.localVariableName;
        let selectedData = retraversalData.find(
          (data) => Object.keys(data)[0] == variableLabel
        );
        let valueData = "";

        if (selectedData !== undefined) {
          valueData = selectedData[variableLabel];
        }
        if (item.inputType == "Date" && valueData == "") {
          let date = new Date();
          let day = date.getDate();
          let year = date.getFullYear();
          let month = date.getMonth() + 1;
          valueData = day + "/" + month + "/" + year;
        }
        let dataObject = {
          variableName: variableName,
          value: item.inputType === 'Number' ? 0 : valueData,
          variableId: item.variableId,
          constraints: item.constraints,
        };
        Object.freeze(dataObject);
        valueNew.push(dataObject);
      });

      setValueArray(valueNew);
    }
  }, [nodeData]);

  const handleConfirm = () => {
    let errorData = valueArray.filter(
      (item) => item.value === "" && item.constraints === "not_null"
    );

    if (errorData.length > 0) {
      setErrorArray(
        errorData.map((item) => {
          return item.variableName;
        })
      );
    } else {
      setErrorArray([]);
      let data = {
        fromLink: nodeData.fromLink,
        toNode: nodeData._id,
        transactionId: transactionId,
        nextNodeLinkId: nodeData.links[0]._id,
        questionId: questionId,
        metaData: {
          inputs: valueArray,
        },
      };
      dispatch(WEB_TRAVERS.saveNodeDataApi(data));
    }
  };

  useEffect(() => {
    if (isConditionalNodeParent) {
      onFieldChange(valueArray)
    }
  }, [valueArray])

  const handleInputChange = (value, variableName, id, date = "") => {
    let inputValue = [...valueArray];
    let enterValue = value;
    let index = inputValue.findIndex(
      (item) => item.variableName === variableName
    );
    let dataObject = {
      variableName: variableName,
      variableId: id,
    };
    if (date !== "") {
      dataObject.timeStamp = date;
    }
    Object.freeze(inputValue[index]);
    if (index !== -1) {
      dataObject.value = enterValue;
      inputValue[index] = dataObject;
    } else {
      dataObject.value = enterValue;
      inputValue.push(dataObject);
    }
    let updatedArr = inputValue
    if (nodeData.metaData.isInputDataPercentage) {
      updatedArr = autoCalculatePercentage(inputValue, index)
    }
    setValueArray(updatedArr);
  };
  const [showContinueButtonAbove, setShowContinueButtonAbove] = useState(false);
  const ref = useRef(null);
  const [disableButton, setDisableButton] = useState(false)

  useEffect(() => {
    if (ref != null) {
      setShowContinueButtonAbove(
        ref.current.clientHeight > traversalSectionHeight
      );
    }
  });

  const autoCalculatePercentage = (arr, index) => {
    let tempArr = [...arr]

    const total = tempArr.reduce((total, item) => {
      const value = parseFloat(item.value, 10);
      return total + (isNaN(value) ? 0 : value);
    }, 0);

    const isLastItem = index === tempArr.length - 1;
    const firstValue = tempArr[0].value;
    const lastValue = tempArr[tempArr.length - 1].value;
    const difference = 100 - total;

    if (!isLastItem) {
      let newValue = parseFloat(lastValue) + difference;
      newValue = newValue > 0 ? newValue : 0;
      tempArr[tempArr.length - 1] = {
        ...tempArr[tempArr.length - 1],
        value: newValue
      };
    } else {
      let newValue = parseFloat(firstValue) + difference;
      newValue = newValue > 0 ? newValue : 0;
      tempArr[0] = {
        ...tempArr[0],
        value: newValue
      };
    }
    return tempArr
  }

  useEffect(() => {
    if (nodeData.metaData.isInputDataPercentage) {
      const total = valueArray.reduce((total, item) => {
        const value = parseInt(item.value);
        return total + (isNaN(value) ? 0 : value);
      }, 0);

      if (total === 100) {
        setDisableButton(false)
      } else {
        setDisableButton(true)
      }
    }
  }, [valueArray])


  return (
    <>
      <div className="card-body" ref={ref}>
        <div className="card-header">
          <h3 className="card-title flex-column">
            <span className="card-label font-weight-bolder text-dark">
              {nodeData?.metaData?.heading}
            </span>
            <span
              className="d-block mt-3 text-sm pr-12"
              style={{
                fontSize: "13px",
              }}
            >{nodeData?.metaData?.labelDescription}</span>
          </h3>
          {showContinueButtonAbove && (
            <TraversalFooter nextNode={() => handleConfirm()} clickAble={disableButton} />
          )}
        </div>
        {nodeData?.metaData?.inputs?.length > 0 &&
          nodeData?.metaData?.inputs?.map((item, index) => {
            let variableName =
              item.variableName !== ""
                ? item.variableName
                : item.localVariableName;
            let findIndex = valueArray.findIndex(
              (values) => values.variableName === variableName
            );
            let InputValue = valueArray[findIndex]?.value;
            if (item.inputType === "Date" && InputValue !== undefined) {
              let dateArray = InputValue.split("/");

              if (dateArray.length > 0 && InputValue !== "") {
                InputValue = new Date(
                  parseInt(dateArray[2]),
                  parseInt(dateArray[1]) - 1,
                  parseInt(dateArray[0])
                );
              } else {
                InputValue = new Date();
              }
            }

            if (item.inputType === "Number" && InputValue === undefined) {
              InputValue = 0;
            }

            return (
              <React.Fragment key={"_" + variableName}>
                <div
                  key={"_" + variableName}
                  className={`input-block ${nodeData?.metaData?.isGridLayout ? "grid-manual-input" : ""
                    }`}
                >
                  <h3 className="input-title mb-5">
                    <span className="font-weight-bolder text-dark">
                      {item.label}
                    </span>
                  </h3>
                  <div className="form-group manual-input d-flex align-items-center">

                    {item.inputType === "Date" ? (
                      <>
                        <DatePicker
                          dateFormat="MM/dd/yyyy"
                          placeholder={item.label}
                          className="form-control form-control-solid h-auto py-5 px-6"
                          showYearDropdown
                          showMonthDropdown
                          selected={InputValue}
                          onChange={(date) => {
                            let day = date.getDate();
                            let year = date.getFullYear();
                            let month = date.getMonth() + 1;
                            handleInputChange(
                              day + "/" + month + "/" + year,
                              variableName,
                              item.variableId,
                              date
                            );
                          }}
                        />
                      </>
                    ) : (
                      <input
                        key={index + "_" + variableName}
                        type={item.inputType === "Number" ? "number" : "text"}
                        className="form-control form-control-solid h-auto py-5 px-6"
                        placeholder={item.label}
                        value={InputValue}
                        onChange={(event) => {
                          if (item.inputType === "String") {
                            handleInputChange(
                              event.target.value,
                              variableName,
                              item.variableId
                            );
                          }
                          if (item.inputType === "Number") {
                            if (event.target.value >= 0) {
                              handleInputChange(
                                event.target.value,
                                variableName,
                                item.variableId,
                                item.inputType
                              );
                            }
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
                {errorArray.findIndex((error) => error == variableName) >
                  -1 && <span className="text-danger">Please add input</span>}
              </React.Fragment>
            );
          })}

        {nodeData?.metaData?.inputs === undefined && (
          <div className="input-block">
            <h3 className="input-title mb-5">
              <span className="font-weight-bolder text-dark">
                {nodeData.metaData.label}
              </span>
            </h3>
            <div className="form-group per__form__group d-flex align-items-center">
              <input
                type="text"
                className="form-control form-control-solid h-auto py-5 px-6"
                placeholder={nodeData.metaData.label}
                value={value}
                onChange={(event) => setValue(event.target.value)}
              />
            </div>
          </div>
        )}
        {nodeData.metaData.isDisplayFooterText && (
          <span className=" font-weight-bolder d-block font-size-lg text-danger">
            {nodeData.metaData.footerText}
          </span>
        )}
      </div>
      {
        isConditionalNodeParent ? "" :
          <div className="card-footer">
            <TraversalFooter nextNode={() => handleConfirm()} clickAble={disableButton} />
          </div>
      }
    </>
  );
}
