import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TraversalFooter } from "../TraversalFooter/TraversalFooter";
import { actions as WEB_TRAVERS } from "../../../../app/modules/WebTravers/_redux/webTraversRedux";

export default function ListingDataInput({
  nodeData,
  questionDetails,
  questionId,
  transactionId,
}) {
  const dispatch = useDispatch();
  const { serviceName } = useSelector((store) => store.webTravers);
  const [inputValues, setInputValues] = useState({});
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  useEffect(() => {
    let initialValues = {};

    if (nodeData.metaData.isInputDataColumnDynamic) {
      let data = [...nodeData.data]
      if (nodeData.metaData.isInputDataPercentage) {
        data.forEach(obj => {
          for (let key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] === "") {
              obj[key] = 0;  // Replace empty string with 0
            }
          }
        });
      }
      initialValues = data.reduce((acc, value) => {
        const serviceKey = serviceName[0]?.ServiceName;
        acc[value[nodeData.metaData.listingDataColumnName]] = value[serviceKey]
          ? ((nodeData.metaData.isInputDataPercentage === false) ? value[serviceKey] : value[serviceKey])
          : nodeData.metaData.isInputDataPercentage ? value[serviceKey] : "";
        return acc;
      }, {});
    } else {
      initialValues = nodeData.data.reduce((acc, value) => {
        const columnValue = value[nodeData.metaData.inputDataColumn]; // e.g. 'Manufacturing'
        acc[value[nodeData.metaData.listingDataColumnName]] = columnValue
          ? ((nodeData.metaData.isInputDataPercentage === false) ? columnValue : columnValue)
          : "";
        return acc;
      }, {});
    }
    setInputValues(initialValues);
  }, [nodeData, serviceName]);

  useEffect(() => {
    if (nodeData.metaData.isInputDataPercentage === false) {
      setIsNextEnabled(true);
    } else {
      const total = Object.values(inputValues).reduce((sum, val) => {
        const num = parseFloat(val) || 0;
        return sum + num;
      }, 0);
      setIsNextEnabled(total === 100);
    }
  }, [inputValues, nodeData.metaData.isInputDataPercentage]);

  const handleChange = (e, marketFactor, index) => {
    let { value } = e.target;

    if (!isNaN(value) && value >= 0 && value <= 100) {
      let updatedValues = {
        ...inputValues,
        [marketFactor]: value,
      };

      const total = Object.values(updatedValues).reduce((sum, val) => {
        const num = parseFloat(val) || 0;
        return sum + num;
      }, 0);

      if (nodeData.metaData.isInputDataPercentage) {
        updatedValues = autoCalculatePercentage(updatedValues, index, total)
      }
      setInputValues(updatedValues);
      setIsNextEnabled(total === 100);
    }
  };

  const autoCalculatePercentage = (obj, index) => {

    let tempObj = { ...obj }

    const total = Object.values(tempObj).reduce((total, item) => {
      const value = parseFloat(item, 10);
      return total + (isNaN(value) ? 0 : value);
    }, 0);

    const isLastItem = index == Object.keys(tempObj).length - 1;
    const firstKey = Object.keys(tempObj)[0];
    const lastKey = Object.keys(tempObj)[Object.keys(tempObj).length - 1];
    const difference = 100 - total;

    if (!isLastItem) {
      let newValue = parseFloat(tempObj[lastKey]) + difference;
      tempObj[lastKey] = newValue > 0 ? newValue : 0;
    } else {
      let newValue = parseFloat(tempObj[firstKey]) + difference;
      tempObj[firstKey] = newValue > 0 ? newValue : 0;
    }
    return { ...tempObj }
  }

  const handleConfirm = () => {
    const processedValues = Object.entries(inputValues).reduce(
      (acc, [key, value]) => {
        acc[key] = value === '' ? 0 : parseFloat(value);
        return acc;
      },
      {}
    );
    const payload = {
      fromLink: nodeData.fromLink,
      toNode: nodeData._id,
      transactionId: transactionId,
      questionId: questionId,
      nextNodeLinkId: nodeData.links[0]._id,
      metaData: {
        variableName: nodeData.metaData.localVariableName,
        variableId: nodeData.metaData.variableId,
        value: processedValues,
      },
    };
    dispatch(WEB_TRAVERS.saveNodeDataApi(payload));
  };

  return (
    <div className="card card-custom">
      <div className="card-body">
        <div className="card-header">
          <h3 className="card-title flex-column mb-2">
            <span className="card-label font-weight-bolder text-dark">
              {nodeData?.label}
            </span>
            <span
              className="d-block mt-3 text-sm pr-12"
              style={{
                fontSize: "13px",
              }}
            >{nodeData?.metaData?.labelDescription}</span>
          </h3>
          <TraversalFooter
            nextNode={handleConfirm}
            clickAble={!isNextEnabled}
          />
        </div>
        <div className="table-responsive">
          <table
            className="table table-head-custom table-vertical-center"
            id="listing_data_input"
          >
            <tbody>
              {nodeData?.data?.map((data, index) => {
                let value = inputValues[data[nodeData.metaData.listingDataColumnName]] ?? ''
                return (
                  <tr key={data?._id}>
                    <td>
                      <span className="text-dark-50 font-weight-bolder d-block font-size-lg">
                        {data[nodeData.metaData.listingDataColumnName]}
                      </span>
                    </td>
                    <td style={{ width: 200 }}>
                      <input
                        className="form-control form-control-solid h-auto py-5 px-6"
                        value={value}
                        onChange={(e) => handleChange(e, data[nodeData.metaData.listingDataColumnName], index)}
                      />
                    </td>
                  </tr>
                )
              }
              )}
            </tbody>
          </table>
        </div>
        {nodeData.metaData.isDisplayFooterText && (
          <span className=" font-weight-bolder d-block font-size-lg text-danger">
            {nodeData.metaData.footerText}
          </span>
        )}
      </div>
      <div className="card-footer">
        <TraversalFooter nextNode={handleConfirm} clickAble={!isNextEnabled} />
      </div>
    </div>
  );
}
