import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import RingLoader from "react-spinners/RingLoader";
import { actions as TRAVERS_HISTORY_ACTIONS } from "../../../../app/modules/TraversHistory/_redux/TraversHistoryRedux";

export function TraversHistoryDialoge({ toggleModal, traversId, ...props }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      TRAVERS_HISTORY_ACTIONS.getTraversHistoryInputDetailsApi(traversId)
    );
    return () => {
      dispatch(
        TRAVERS_HISTORY_ACTIONS.getTraversHistoryInputDetails({
          item: [],
          displayColumn: [],
          displayGroupByColumn: [],
        })
      );
    };
  }, []);
  const inputDetails = useSelector(
    (store) => store.traversHistory?.traversInputs?.item
  );
  const displayNames = useSelector(
    (store) => store.traversHistory?.traversInputs?.displayColumn
  );
  const displayGroupByNames = useSelector(
    (store) => store.traversHistory?.traversInputs?.displayGroupByColumn
  );

  const isLoading = useSelector((store) => store.traversHistory.isLoading);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        toggleModal(false);
      }}
    >
      <Modal.Body>
        <h3 className="mt-2 pb-6">Traversal Detail</h3>
        {/* {isLoading && (
          <div className="loader_wrapper">
            <RingLoader color={"darkcyan"} loading={true} size={40} />
          </div>
        )}{" "} */}
        <IconButton
          position="top right"
          aria-label="close"
          onClick={() => {
            toggleModal(false);
          }}
          style={{
            position: "absolute",
            zIndex: 1111,
            right: 22,
            top: 17,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        {/* <pre>{JSON.stringify(data, 0, 2)}</pre> */}
        {isLoading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "20vh",
            }}
            className=""
          >
            <RingLoader color={"darkcyan"} loading={true} size={40} />
          </div>
        )}
        {!isLoading && (
          <div style={{ height: "75vh" }} className="table-responsive">
            <table
              className="table table-head-custom table-vertical-center"
              id="kt_advance_table_widget_1"
            >
              <tbody>
                {inputDetails?.length > 0 &&
                  inputDetails?.map?.((item, index) => {
                    let key = Object.keys(item)[0];

                    if (typeof item[key] === "string" || typeof item[key] === "number") {
                      return (
                        <tr key={index}>
                          <th>{key}</th>
                          <td>{item[key]}</td>
                        </tr>
                      );
                    }

                    if (typeof item[key] === "object") {
                      if (key === "reportSummary") return null;
                      let dataValue = item[key];

                      if (Array.isArray(dataValue)) {
                        let displayNamevalue = displayNames.find((name) => Object.keys(name)[0] === key);
                        let displayGroupByNamevalue = displayGroupByNames.find((name) => Object.keys(name)[0] === key);

                        if (displayNamevalue === undefined) return null
                        displayNamevalue = displayNamevalue ? displayNamevalue[key]?.split(" ") : [];
                        displayGroupByNamevalue = displayGroupByNamevalue ? displayGroupByNamevalue[key]?.split(" ") : [];

                        // Configuration for how to show data either group by or without it
                        let showDataByGroupByName = true;
                        let comparisonArr = showDataByGroupByName ? displayGroupByNamevalue : displayNamevalue;

                        // modify data by displayColumns
                        dataValue = dataValue.map(obj =>
                          Object.keys(obj)
                            .filter(key => comparisonArr.includes(key)) // Keep only matching keys
                            .reduce((acc, key) => {
                              acc[key] = obj[key]; // Add key-value pair to new object
                              return acc;
                            }, {})
                        );

                        let stringArr = dataValue.map(obj => Object.values(obj).filter(val => val != '').join(" » "));
                        const valueString = stringArr.join(" <br> ");

                        return (
                          <tr key={index + "_" + key}>
                            <th>{key}</th>
                            <td dangerouslySetInnerHTML={{ __html: valueString }}></td>
                          </tr>
                        );
                      }

                      if (!Array.isArray(dataValue)) {
                        const keys = Object.keys(dataValue);
                        return (
                          <tr key={index + "_" + key}>
                            <th>{key}</th>
                            <td>
                              {keys.map((subKey) => (
                                <div key={subKey}>
                                  <strong>{subKey}:</strong> {dataValue[subKey] || "N/A"}
                                </div>
                              ))}
                            </td>
                          </tr>
                        );
                      }
                    }
                  })}
              </tbody>
            </table>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
