import axios from "axios";
import persistStore from "redux-persist/es/persistStore";
import store from "../redux/store";

const API_URL = process.env.REACT_APP_API_URL || "http://emate.ics-global.in:64460/";
let headers = {
  Accept: "application/json",
};

const instance = axios.create({
  baseURL: API_URL,
  // timeout: 100000,
  headers: headers,

});


// Function to handle logout
const logoutUser = () => {
  // Clear local storage or session storage
  localStorage.removeItem("authToken");

  // Clear Redux Persist state (optional)
  const persistor = persistStore(store);
  persistor.purge();

  // Redirect to login page
  window.location.href = "/logout";
};

instance.interceptors.response.use(
  (response) => {
    // Pass through successful responses
    return response;
  },
  (error) => {
    // Handle 401 Unauthorized
    if (error.response && error.response.status === 401) {
      logoutUser();
    }

    // Return error to the caller
    return Promise.reject(error);
  }
);

export const Services = instance;
