import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RingLoader } from "react-spinners";
// import "./StartTravesal.scss";
import { TraversalFooter } from "../TraversalFooter/TraversalFooter";
import { actions as WEB_TRAVERS } from "../../../../app/modules/WebTravers/_redux/webTraversRedux";
import "./CommentComponent.scss";
export function CommentComponent({ nodeData, questionId, questionDetails, transactionId }) {

  const dispatch = useDispatch()
  const handleConfirm = () => {
    let data = {
      fromLink: nodeData.fromLink,
      toNode: nodeData._id,
      transactionId: transactionId,
      nextNodeLinkId: nodeData.links[0]._id,
      questionId: questionId,
    }
    dispatch(WEB_TRAVERS.saveNodeDataApi(data))


  }
  return (
    <div className="TraversalComments__main">
      <div className="card-body">
        {/* <h2 className="mb-5">
          <span className="font-weight-bolder text-dark">Comments</span>
        </h2> */}
        <div className="commentsbox">
          <p className="text-dark">
            {nodeData.metaData.comment}
          </p>
          <div className="action__btn pt-2">
            <TraversalFooter nextNode={() => handleConfirm()} />
          </div>
        </div>
      </div>
    </div>

  );
}
