export const formValidations = {
  usersForm: {
    firstName: "First name is required.",
    invalidFirstName: "First name should be valid string.",
    lastName: "Last name is required.",
    invalidLastName: "Last name should be valid string.",
    email: "Email is required.",
    invalidEmail: "Invalid Email.",
    phone: "Phone Number is required.",
    invalidPhone: "Invalid Phone Number.",
    designation: "Designation is required.",
    password: "Password is required.",
    invalidDesignation: "Designation should have more than 3 letters.",
    invalidDesignationString:
      "Designation should be start with string and only accepts letters, digits and spaces",
    countryCode: "Country code is required.",
    userRole: "User role is required",
    minPassword: "Password must be greater than 8 characters",
    maxPassword: "Password must be less than or equal to 64 characters",
    organizationId: "Organization is required.",
  },
  organizationsForm: {
    companyName: "Company name is required.",
    geographicScope: "Grographic scope is required.",
    city: "City is required.",
    state: "State is required.",
    productSold: "Product sold is required.",
    salesChannelUtilized: "Sales channel utilized is required.",
  },
  diagramForm: {
    question: "Question is required",
    variableName: "Variable Name is required.",
    invalidVariableName:
      "Variable name should be valid with Character, Number and '_'.",
  },
  questionForm: {
    text: "Tool Name is required",
    validText: "Enter Valid Tool Name",
    assumptions: "Assumptions is required",
    screen_tagline: "Screen Tagline is required",
    description: "Description is required",
    summery_report: "Summary Report is required",
    icon_logo: "Logo is required",
  },
};

export const traversalSectionHeight = 480;

export const groupByColumnName = (arr, key) => {
  return Object.values(
    arr.reduce((acc, obj) => {
      if (!acc[obj[key]]) {
        acc[obj[key]] = { [key]: obj[key], list: [] };
      }
      acc[obj[key]].list.push(obj);
      return acc;
    }, {})
  );
}

// check if ID Exists or not by converting into an array of series of id and match the id Parent ID 
export const checkIDExist = (searchId, parentId) => {
  let arr = searchId.split('-')
  const result = arr.reduce((acc, curr, index) => {
    const lastItem = acc[index - 1] || ""; // Get the previous item or an empty string
    acc.push(lastItem ? `${lastItem}-${curr}` : curr); // Append the new concatenated string
    return acc;
  }, []);
  return result.includes(parentId)
}