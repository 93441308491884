import { id } from "date-fns/locale";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { call, put, takeLatest } from "redux-saga/effects";
import * as WEB_TRAVERS_SERVICE from "../../../../_service/WebTravers/webTravers.service";
import { groupByColumnName } from "../../../../_metronic/_utils/constants";

export const actionTypes = {
  START_TRAVERS_API_CALL: "[WEB_TRAVERS_START_TRAVERS_API_CALL] Action",
  GET_NEXT_NODE_API_CALL: "[GET_NEXT_NODE_API_CALL] Action",
  SAVE_NODE_DATA_API_CALL: "[SAVE_NODE_DATA_API_CALL] Action",
  END_NODE_DATA_API_CALL: "[END_NODE_DATA_API_CALL] Action",
  GET_VIEW_REPORT_API_CALL: "[GET_VIEW_REPORT_API_CALL] Action",
  GET_NODE_DETAILS_API_CALL: "[GET_NODE_DETAILS_API_CALL] Action",
  SET_TRAVERSACTION_ID: "[SET_TRAVERSACTION_ID] Action",
  NODE_IS_LOADING: "[NODE_IS_LOADING] Action",
  REPORT_IS_LOADING: "[REPORT_IS_LOADING] Action",
  DATA_IS_LOADING: "[DATA_IS_LOADING] Action",
  SET_NEXT_NODE_DATA: "[SET_NEXT_NODE_DATA] Action",
  SET_VIEW_REPORT_API_CALL: "[SET_VIEW_REPORT_API_CALL] Action",
  ERROR: "[ERROR WEB TRAVERS] Action",
  RESET_TO_INITIAL_STATE: "[RESET_TO_INITIAL_STATE] Action",
  SET_NODE_DETAILS: "[SET_NODE_DETAILS] Action",
  GET_DOWNLOAD_REPORT_API: "[GET_DOWNLOAD_REPORT_API] Action",
  GET_DOWNLOAD_REPORT: "[GET_DOWNLOAD_REPORT] Action",
  GET_SUMMARY_DATA_API: "[GET_SUMMARY_DATA_API] Action",
  GET_SUMMARY_DATA: "[GET_SUMMARY_DATA] Action",
  GET_SUMMARY_START_DATA_API: "[GET_SUMMARY_START_DATA_API] Action",
  GET_SUMMARY_START_DATA: "[GET_SUMMARY_START_DATA] Action",
  DOWNLOAD_REPORT_TO_INITIAL_STATE: "[DOWNLOAD_REPORT_TO_INITIAL_STATE] Action",
  SAVE_CONDITIONAL_NODE_DATA_API_CALL:
    "[SAVE_CONDITIONAL_NODE_DATA_API_CALL] Action",
  RE_TRAVERSAL_API_CALL: "[RE_TRAVERSAL_API_CALL] Action",
  SET_SERVICE_NAME: "[SET_SERVICE_NAME] Action",
};

const initialWebTraversalState = {
  transactionId: "",
  nodeIsLoading: false,
  reportIsLoading: false,
  reportType: "",
  dataIsLoading: false,
  downloadReprtReady: false,
  nodeData: {},
  viewReport: "",
  conditionNodeDetails: [],
  downloadReport: "",
  summaryData: {
    item: [],
    displayColumn: [],
    displayGroupByColumn: [],
  },
  retraversalData: [],
  countryName: [],
  serviceName: [],
};

export const reducer = persistReducer(
  { storage, key: "webTravers" },
  (state = initialWebTraversalState, action) => {
    switch (action.type) {
      case actionTypes.NODE_IS_LOADING: {
        const { isLoading } = action.payload;
        return { ...state, nodeIsLoading: isLoading };
      }
      case actionTypes.REPORT_IS_LOADING: {
        const { isLoading } = action.payload;
        return { ...state, reportIsLoading: isLoading };
      }
      case actionTypes.DATA_IS_LOADING: {
        const { isLoading } = action.payload;
        return { ...state, dataIsLoading: isLoading };
      }

      case actionTypes.ERROR: {
        const { error } = action.payload;
        return { ...state, error };
      }
      case actionTypes.SET_TRAVERSACTION_ID: {
        const { transactionId } = action.payload;
        return { ...state, transactionId };
      }
      case actionTypes.SET_NEXT_NODE_DATA: {
        const { result } = action.payload;
        return { ...state, nodeData: result };
      }
      case actionTypes.GET_DOWNLOAD_REPORT: {
        const { result } = action.payload;
        return { ...state, downloadReport: result, downloadReprtReady: true };
      }
      case actionTypes.GET_SUMMARY_DATA: {
        const { result } = action.payload;
        return { ...state, summaryData: result };
      }
      case actionTypes.GET_SUMMARY_START_DATA: {
        const { result } = action.payload;
        return { ...state, retraversalData: result };
      }
      case actionTypes.DOWNLOAD_REPORT_TO_INITIAL_STATE: {
        const { result } = action.payload;
        return {
          ...state,
          downloadReport: "",
          viewReport: "",
          downloadReprtReady: false,
        };
      }

      case actionTypes.SET_VIEW_REPORT_API_CALL: {
        const { result } = action.payload;
        return {
          ...state,
          viewReport: JSON.stringify(result.displayHtml),
          reportType: result.reportType,
        };
      }
      case actionTypes.SET_NODE_DETAILS: {
        const { result } = action.payload;
        return { ...state, conditionNodeDetails: result };
      }
      case actionTypes.RESET_TO_INITIAL_STATE: {
        return { ...initialWebTraversalState };
      }

      case actionTypes.SET_SERVICE_NAME: {
        const { serviceName } = action.payload;

        return { ...state, serviceName };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  startTraversalApi: (id) => ({
    type: actionTypes.START_TRAVERS_API_CALL,
    payload: {
      questionId: id,
    },
  }),
  getNextNodeApi: (data) => ({
    type: actionTypes.GET_NEXT_NODE_API_CALL,
    payload: {
      data,
    },
  }),
  saveNodeDataApi: (nodeData) => ({
    type: actionTypes.SAVE_NODE_DATA_API_CALL,
    payload: {
      nodeData,
    },
  }),
  endNodeDataApi: (nodeData) => ({
    type: actionTypes.END_NODE_DATA_API_CALL,
    payload: {
      nodeData,
    },
  }),
  saveConditionalNodeDataApi: (nodeData) => ({
    type: actionTypes.SAVE_CONDITIONAL_NODE_DATA_API_CALL,
    payload: {
      nodeData,
    },
  }),
  getNodeDetailsApi: (data) => ({
    type: actionTypes.GET_NODE_DETAILS_API_CALL,
    payload: data,
  }),
  getViewReportApi: (transactionId) => ({
    type: actionTypes.GET_VIEW_REPORT_API_CALL,
    payload: {
      transactionId,
    },
  }),
  getDownloadReportApi: (transactionId) => ({
    type: actionTypes.GET_DOWNLOAD_REPORT_API,
    payload: {
      transactionId,
    },
  }),
  setNextNodeData: (result) => ({
    type: actionTypes.SET_NEXT_NODE_DATA,
    payload: {
      result,
    },
  }),
  setNodeDetails: (result) => ({
    type: actionTypes.SET_NODE_DETAILS,
    payload: {
      result,
    },
  }),
  setViewReportData: (result) => ({
    type: actionTypes.SET_VIEW_REPORT_API_CALL,
    payload: {
      result,
    },
  }),
  setDownloadReportData: (result) => ({
    type: actionTypes.GET_DOWNLOAD_REPORT,
    payload: {
      result,
    },
  }),
  nodeIsLoading: (isLoading) => ({
    type: actionTypes.NODE_IS_LOADING,
    payload: { isLoading },
  }),
  reportIsLoading: (isLoading) => ({
    type: actionTypes.REPORT_IS_LOADING,
    payload: { isLoading },
  }),
  dataIsLoading: (isLoading) => ({
    type: actionTypes.DATA_IS_LOADING,
    payload: { isLoading },
  }),
  setTraversalId: (transactionId) => ({
    type: actionTypes.SET_TRAVERSACTION_ID,
    payload: {
      transactionId: transactionId,
    },
  }),
  setRetraversalDataApi: (nodeData) => ({
    type: actionTypes.RE_TRAVERSAL_API_CALL,
    payload: {
      nodeData,
    },
  }),
  error: (error) => ({ type: actionTypes.ERROR, payload: { error } }),
  resetToInitialState: () => ({
    type: actionTypes.RESET_TO_INITIAL_STATE,
    payload: {},
  }),
  setDownloadReportInitialData: () => ({
    type: actionTypes.DOWNLOAD_REPORT_TO_INITIAL_STATE,
    payload: {},
  }),
  getSummaryDataApi: (transactionId) => ({
    type: actionTypes.GET_SUMMARY_DATA_API,
    payload: { transactionId },
  }),
  setSummaryData: (result) => ({
    type: actionTypes.GET_SUMMARY_DATA,
    payload: { result },
  }),
  // getSummaryStartDataApi:(transactionId) =>({ type: actionTypes.GET_SUMMARY_START_DATA_API, payload: { transactionId } }),
  setSummaryStartData: (result) => ({
    type: actionTypes.GET_SUMMARY_START_DATA,
    payload: { result },
  }),
  setServiceName: (serviceName) => ({
    type: actionTypes.SET_SERVICE_NAME,
    payload: { serviceName },
  }),
};

export function* saga() {
  yield takeLatest(
    actionTypes.START_TRAVERS_API_CALL,
    function* startTraversApiCall(action) {
      yield put(actions.nodeIsLoading(true));
      try {
        const { questionId } = action.payload;

        const response = yield call(
          WEB_TRAVERS_SERVICE.startTraversCall,
          action.payload
        );
        yield put(actions.setTraversalId(response.data?.data?.transactionId));
        if (response.data?.data?.transactionId != "") {
          yield put(
            actions.getNextNodeApi({
              questionId: questionId,
              transactionId: response.data?.data?.transactionId,
            })
          );
        }
      } catch (error) {
        yield put(actions.nodeIsLoading(false));
        yield put(actions.error("Failed to Create Transaction!"));
      }
    }
  );
  yield takeLatest(
    actionTypes.SAVE_NODE_DATA_API_CALL,
    function* saveNodeDataApiCall(action) {
      yield put(actions.nodeIsLoading(true));
      try {
        const { nodeData } = action.payload;
        let data = {
          fromLink: nodeData.fromLink,
          toNode: nodeData.toNode,
          transactionId: nodeData.transactionId,
        };
        if (nodeData?.metaData !== undefined) {
          data.metaData = nodeData?.metaData;
        }

        // Condition set by Tushar
        if (nodeData?.metaData?.value?.length > 0 && nodeData?.metaData?.value.some(item => typeof item == "object" && 'ServiceName' in item)) {
          yield put(actions.setServiceName(nodeData?.metaData?.value));
        }

        const response = yield call(
          WEB_TRAVERS_SERVICE.saveTraversalNodeApiCall,
          data
        );

        if (response.data?.data?.transactionId !== "") {
          const linkId = nodeData?.endNode === undefined ? nodeData?.nextNodeLinkId : nodeData?.endNode.endNodefromLink
          yield put(
            actions.getNextNodeApi({
              questionId: nodeData?.questionId,
              transactionId: nodeData?.transactionId,
              linkId: linkId,
            })
          );
        } else {
          yield put(actions.nodeIsLoading(false));
        }
      } catch (error) {
        yield put(actions.nodeIsLoading(false));
        yield put(actions.error("Failed to Create Transaction!"));
      }
    }
  );

  yield takeLatest(
    actionTypes.SAVE_CONDITIONAL_NODE_DATA_API_CALL,
    function* saveConditionalNodeDataApiCall(action) {
      yield put(actions.nodeIsLoading(true));
      try {
        const { nodeData } = action.payload;
        let data = {
          fromLink: nodeData.fromLink,
          toNode: nodeData.toNode,
          transactionId: nodeData.transactionId,
        };
        const response = yield call(
          WEB_TRAVERS_SERVICE.saveTraversalNodeApiCall,
          data
        );
        let nextData = {
          fromLink: nodeData.nextLinkSelection.fromLink,
          toNode: nodeData.nextLinkSelection._id,
          transactionId: nodeData.transactionId,
          metaData: nodeData.metaDetails,
        };
        const getRespose = yield call(
          WEB_TRAVERS_SERVICE.saveTraversalNodeApiCall,
          nextData
        );
        // yield put(actions.setTraversalId(response.data?.data?.transactionId));
        if (getRespose.data?.data?.transactionId != "") {
          yield put(
            actions.getNextNodeApi({
              questionId: nodeData?.questionId,
              transactionId: nodeData?.transactionId,
              linkId: nodeData?.nextLinkSelection?.links[0]._id,
            })
          );
        } else {
          yield put(actions.nodeIsLoading(false));
        }
      } catch (error) {
        yield put(actions.nodeIsLoading(false));
        yield put(actions.error("Failed to Create Transaction!"));
      }
    }
  );
  yield takeLatest(
    actionTypes.GET_NODE_DETAILS_API_CALL,
    function* getNodeDetailsApiCallSaga(action) {
      yield put(actions.dataIsLoading(true));
      try {
        const { decisionNodeId, transactionId } = action.payload;
        const response = yield call(WEB_TRAVERS_SERVICE.getNodeDetailsApiCall, {
          decisionNodeId,
          transactionId,
        });

        // Custom modifications of States data 
        const name = "StateName Region"
        if (response.data?.data[0]?.metaData.localVariableName === "States" && response.data?.data[0]?.type === "MultiOption") {

          response.data.data[0].metaData.displayColumnName = name
          response.data.data[0].data = groupByColumnName(response.data?.data[0].data, 'Region')

        } else if (response.data?.data[1]?.metaData.localVariableName === "States" && response.data?.data[1]?.type === "MultiOption") {
          response.data.data[1].metaData.displayColumnName = name
          response.data.data[1].data = groupByColumnName(response.data?.data[1].data, 'Region')
        }

        yield put(actions.setNodeDetails(response.data.data));
        if (response.data?.data) {
          yield put(actions.dataIsLoading(false));
        }
      } catch (error) {
        yield put(actions.dataIsLoading(false));
        yield put(actions.error("Failed to Get Data!"));
      }
    }
  );
  yield takeLatest(
    actionTypes.GET_NEXT_NODE_API_CALL,
    function* getNextNodeApiCall(action) {
      yield put(actions.nodeIsLoading(true));
      try {
        const { data } = action.payload;
        const response = yield call(
          WEB_TRAVERS_SERVICE.getNextNodeDetails,
          data
        );

        // Custom modifications of States data
        const name = "StateName Region"
        if (response.data?.data?.metaData.localVariableName === "States" && response.data?.data?.type === "MultiOption") {

          response.data.data.metaData.displayColumnName = name
          response.data.data.data = groupByColumnName(response.data?.data.data, 'Region')

        } else if (response.data?.data?.metaData.localVariableName === "States" && response.data?.data?.type === "MultiOption") {
          response.data.data.metaData.displayColumnName = name
          response.data.data.data = groupByColumnName(response.data?.data.data, 'Region')
        }

        yield put(actions.setNextNodeData(response.data?.data));
      } catch (error) {
        yield put(actions.error("Failed to fetch next node!"));
      }
      yield put(actions.nodeIsLoading(false));
    }
  );
  yield takeLatest(
    actionTypes.GET_VIEW_REPORT_API_CALL,
    function* getViewReportApiCall(action) {
      yield put(actions.reportIsLoading(true));
      try {
        const { transactionId } = action.payload;
        const response = yield call(
          WEB_TRAVERS_SERVICE.getViewReportDetails,
          transactionId
        );
        yield put(actions.setViewReportData(response.data?.data));
      } catch (error) {
        yield put(actions.error("Failed to fetch next node!"));
      }
      yield put(actions.reportIsLoading(false));
    }
  );
  yield takeLatest(
    actionTypes.GET_DOWNLOAD_REPORT_API,
    function* getDownloadReportApiCall(action) {
      yield put(actions.reportIsLoading(true));
      try {
        const { transactionId } = action.payload;
        const response = yield call(
          WEB_TRAVERS_SERVICE.getDownloadReportDetails,
          transactionId
        );
        yield put(actions.setDownloadReportData(response.data?.data));
      } catch (error) {
        yield put(actions.error("Failed to fetch next node!"));
      }
      yield put(actions.reportIsLoading(false));
    }
  );
  yield takeLatest(
    actionTypes.GET_SUMMARY_DATA_API,
    function* getSummaryDataApiCall(action) {
      yield put(actions.dataIsLoading(true));
      try {
        const { transactionId } = action.payload;
        const response = yield call(
          WEB_TRAVERS_SERVICE.getSummaryData,
          transactionId
        );
        yield put(actions.setSummaryData(response.data?.data));
      } catch (error) {
        yield put(actions.error("Failed to fetch data!"));
      }
      yield put(actions.dataIsLoading(false));
    }
  );
  yield takeLatest(
    actionTypes.GET_SUMMARY_START_DATA_API,
    function* getSummaryStartDataApiCall(action) {
      yield put(actions.dataIsLoading(true));
      try {
        const { transactionId } = action.payload;
        const response = yield call(
          WEB_TRAVERS_SERVICE.getSummaryData,
          transactionId
        );
        yield put(actions.setSummaryStartData(response.data?.data));
      } catch (error) {
        yield put(actions.error("Failed to fetch data!"));
      }
      yield put(actions.dataIsLoading(false));
    }
  );
  yield takeLatest(
    actionTypes.RE_TRAVERSAL_API_CALL,
    function* reTraversalApiCall(action) {
      yield put(actions.dataIsLoading(true));
      try {
        const { nodeData } = action.payload;
        const response = yield call(
          WEB_TRAVERS_SERVICE.reTraversalApi,
          nodeData.transactionId
        );
        if (response.data?.data?.transactionId != "") {
          yield put(
            actions.setSummaryStartData(response.data?.data?.variableList)
          );
          yield put(
            actions.getNextNodeApi({
              questionId: nodeData?.questionId,
              transactionId: nodeData?.transactionId,
            })
          );
        } else {
          yield put(actions.nodeIsLoading(false));
        }
      } catch (error) {
        yield put(actions.error("Failed to fetch data!"));
      }
      yield put(actions.dataIsLoading(false));
    }
  );
  yield takeLatest(
    actionTypes.END_NODE_DATA_API_CALL,
    function* endNodeDataApiCallSaga(action) {
      yield put(actions.nodeIsLoading(true));
      try {
        const { nodeData } = action.payload;
        let data = {
          fromLink: nodeData.fromLink,
          toNode: nodeData.toNode,
          transactionId: nodeData.transactionId,
        };

        const response = yield call(
          WEB_TRAVERS_SERVICE.saveTraversalNodeApiCall,
          data
        );
        // yield put(actions.setTraversalId(response.data?.data?.transactionId));

        if (response.data?.data?.transactionId != "") {
          yield call(
            WEB_TRAVERS_SERVICE.completeTraversalNodeApiCall({
              transactionId: nodeData?.transactionId,
            })
          );
        } else {
          yield put(actions.nodeIsLoading(false));
        }
      } catch (error) {
        yield put(actions.nodeIsLoading(false));
        yield put(actions.error("Failed to Create Transaction!"));
      }
    }
  );
}
