import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RingLoader } from "react-spinners";
import "../Traversal.scss";
import { actions as WEB_TRAVERS } from "../../../../app/modules/WebTravers/_redux/webTraversRedux";

export function ViewReport({ transactionId }) {
  const dispatch = useDispatch();
  const reportIsLoading = useSelector(
    (store) => store.webTravers.reportIsLoading
  );
  const viewReport = useSelector((store) => store.webTravers.viewReport);
  const reportType = useSelector((store) => store.webTravers.reportType);
  useEffect(() => {
    dispatch(WEB_TRAVERS.getViewReportApi(transactionId));
    return () => {
      dispatch(WEB_TRAVERS.setViewReportData(""));
    };
  }, []);

  return (
    <>
      {reportIsLoading ? (
        <div className="loader_wrapper">
          <RingLoader color={"darkcyan"} loading={true} size={40} />
        </div>
      ) : (
        <>
          {viewReport !== "" && viewReport !== undefined && (
            <>
              {
                reportType == "nearshoreTemplate" ||
                  reportType == "countryEvaluationTemplate" ||
                  reportType == "tariffRaterTemplate" ||
                  reportType == "networkComputation" ? (
                  <iframe
                    className="report-div-iframe"
                    title="Embedded HTML"
                    srcDoc={JSON.parse(viewReport)}
                    width="100%"
                    height="400"
                  />
                ) : (
                  <div
                    className="report-div-html"
                    id="report-div-html"
                    dangerouslySetInnerHTML={{ __html: JSON.parse(viewReport) }}
                  />
                )}
            </>
          )}
        </>
      )}
    </>
  );
}
